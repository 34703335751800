<template>
    <div class="orderDetail">
        <van-tabs v-model="active" class="van_tab">
            <van-tab title="药品订单" :name="index" v-for="(item, index) in 3"></van-tab>
        </van-tabs>
        <div class="content">
            <div class="address">
                <img class="icon_left" src="@/assets/img/icon_address.png" alt="">
                <div class="middle">
                    <p class="name">
                        <span>李子明</span>
                        <span>15637823357</span>
                    </p>
                    <p class="detail">深圳市南山区深南大道9966号威盛科技大厦 1210室</p>
                </div>
                <img class="icon_right" src="@/assets/img/back.png" alt="">
            </div>
            <div class="shop">
                <div class="drugName">
                    <img src="@/assets/img/icon_address.png" alt="">
                    <p>阿歪大药房</p>
                </div>
                <div class="drugInfo">
                    <img class="drugImg" src="@/assets/img/icon_17.png" alt="">
                    <div class="info">
                        <div class="name">
                            <span class="t1">同仁堂生脉饮（人参方）</span>
                            <p class="drugPrice">
                                <span>￥</span>
                                <span>268.00</span>
                            </p>
                        </div>
                        <p class="spec">
                            <span>10粒*2板</span>
                            <span>x 1</span>
                        </p>
                        <p class="consumption">
                            <span>用法用量 2次/天，每次一片</span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <p class="left">商品总价</p>
                    <p class="right">
                        <span>￥</span>
                        <span>50.00</span>
                    </p>
                </div>
                <div class="row">
                    <p class="left">配送费 快递配送</p>
                    <p class="right">
                        <span>￥</span>
                        <span>5.00</span>
                    </p>
                </div>
                <div class="row">
                    <p class="left">实付价格</p>
                    <p class="right realPrice">
                        <span>￥</span>
                        <span>50.00</span>
                    </p>
                </div>
            </div>
            <div class="orderInfo">
                <p class="title">订单信息</p>
                <div class="row">
                    <p>订单编号</p>
                    <p>239482394823894</p>
                </div>
                <div class="row">
                    <p>交易形式</p>
                    <p>支付宝</p>
                </div>
                <div class="row">
                    <p>备注</p>
                    <p>我是备注</p>
                </div>
                <div class="row">
                    <p>创建时间</p>
                    <p>2020-05-26 12:30</p>
                </div>
                <div class="row">
                    <p>发货时间</p>
                    <p>2020-05-26 12:30</p>
                </div>
                <div class="row">
                    <p>物流编号</p>
                    <p class="right">
                        <span>298374827482374</span>
                        <span>复制</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0
        }
    }
}
</script>

<style lang="less" scoped>
.orderDetail {
  min-height: 100%;
  background: #f8f8f8;
  .van_tab {
    position: fixed;
    width: 100%;
    margin-bottom: 0.44rem;
    color: #4a4a4a;
    /deep/ .van-tab__text {
      font-size: 0.28rem;
    }
    /deep/ .van-tab--active {
      color: #3798ee;
      font-size: 0.32rem;
      font-weight: bold;
    }
    /deep/ .van-tabs__line {
      background-color: #3798ee;
    }
  }
  .content {
    padding: 0.2rem 0.24rem;
    width: calc(100% - 0.48rem);
    padding-top: 1.16rem;
    .address {
      display: flex;
      align-items: center;
      padding: 0.24rem;
      width: calc(100% - 0.48rem);
      background: #fff;
      border-radius: 4px;
      .icon_left {
        width: 0.56rem;
        height: 0.56rem;
        border-radius: 50%;
        margin-right: 0.24rem;
      }
      .middle {
        flex: 1;
        font-size: 0.26rem;
        color: #333333;
        margin-right: 0.68rem;
        .name {
          span:first-child {
            color: #333333;
            font-size: 0.28rem;
            margin-right: 0.26rem;
          }
          span:last-child {
            color: #999999;
            font-size: 0.24rem;
          }
        }
      }
      .icon_right {
        width: 0.14rem;
        height: 0.28rem;
      }
    }
    .shop {
      margin-top: 0.2rem;
      padding: 0.3rem 0.28rem;
      width: calc(100% - 0.56rem);
      background: #fff;
      border-radius: 4px;
      .drugName {
        margin-bottom: 0.24rem;
        display: flex;
        align-items: center;
        img {
          width: 0.52rem;
          height: 0.52rem;
          margin-right: 0.2rem;
        }
        p {
          font-size: 0.3rem;
          color: #333333;
          font-weight: 700;
        }
      }
      .drugInfo {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 0.4rem;
        .drugImg {
          width: 1.44rem;
          height: 1.44rem;
          margin-right: 0.16rem;
          vertical-align: middle;
        }
        .info {
          width: calc(100% - 1.6rem);
          //   .name,
          //   .spec {
          //     display: flex;
          //     align-items: center;
          //     justify-content: space-between;
          //   }
          .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.16rem;
            .t1 {
              font-size: 0.28rem;
              color: #333333;
            }
            .drugPrice {
              color: #ffa438;
              font-weight: bold;
              span:first-child {
                font-size: 0.22rem;
              }
              span:last-child {
                font-size: 0.28rem;
              }
            }
          }
          .spec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.24rem;
            margin-bottom: 0.22rem;
            span:first-child {
              color: #999;
            }
            span:last-child {
              color: #666;
            }
          }
          .consumption {
            font-size: 0.24rem;
            color: #999;
          }
        }
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.26rem;
        .left {
          font-size: 0.28rem;
          color: #333333;
        }
        .right {
          color: #333333;
          font-weight: bold;
          span:first-child {
            font-size: 0.24rem;
          }
          span:last-child {
            font-size: 0.28rem;
          }
        }
        .realPrice {
          color: #ffa438;
        }
      }
      .row:last-child {
        margin-bottom: 0;
      }
    }
    .orderInfo {
      padding: 0.2rem 0.24rem;
      width: calc(100% - 0.48rem);
      background: #fff;
      margin-top: 0.2rem;
      .title {
        font-size: 0.3rem;
        color: #333333;
        margin-bottom: 0.24rem;
        font-weight: bold;
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.32rem;
        color: #333333;
        font-size: 0.28rem;
        .right {
          span:first-child {
            margin-right: 0.54rem;
          }
          span:last-child {
            color: #ffa438;
          }
        }
      }
      .row:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

